:root {
  --primary: #232F3E;
  --secondary: #F3A847;
  --tertiary: #B12704;
  --light: #fafafa;
  --dark: #272f32;
  --error: rgb(228, 46, 1);
  --spacing-one: calc(1rem * 0.5);
  --spacing-two: calc(1rem);

  /* --m-primary: #0d77e1; */
  --m-primary: #E40B0B;
}

* {
  box-sizing: border-box;
}

html,
body {
  min-height: 100vh;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
  /* font-family: 'Karla', sans-serif; */
  font-weight: 400;
  line-height: 1.5;
  font-size: 16px;
  color: var(--dark);
  background-color: #E6DABF;
  font-family: "Aclonica", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Rubik', sans-serif;
  font-family: "Aclonica", sans-serif;
  font-weight: 500;
  margin: 5px 0;
  line-height: 1.25;
}

h1 {
  font-size: 2.25rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

p {
  font-size: 1.1rem;
  margin-top: 0;
}

a {
  /* color: #E40B0B; */
  text-decoration: none;
  /* font-weight: bold; */
}

a:hover {
  text-decoration: none;
}

header {
  background: var(--primary);
}

header a {
  color: var(--light);
}

button {
  font-size: 1rem;
  text-decoration: none;
  padding: 0.25rem 1rem;
  border-radius: .5rem;
  border: none;
  outline: none;
  cursor: pointer;
  margin: 0 1px;
  background-color: #ffffff;
  /* background-color: var(--secondary); */
}

button:hover {
  opacity: .8;
}

/* button[disabled] {
  cursor: default;
  opacity: .8;
} */

img {
  max-width: 100%;
}

span[role="img"] {
  cursor: default;
}



input {
  font-size: 1rem;
  padding: 0.25rem;
  border: 1px solid var(--dark);
  outline: none;
  border-radius: 3px;
  transition: box-shadow 0.2s;
}

input:focus {
  border: 1px solid var(--secondary);
  box-shadow: 0 0 .25rem rgba(0, 0, 0, .5);
}



.container {
  /* display: flex;
  height: 100vh; */
  /* width: 85%;
  max-width: 1200px;
  margin-left: calc((100vw - 1200px) / 2);
  margin-right: calc((100vw - 1200px) / 2); */
}

/* .container-admin {
  display: flex;
  flex-direction: column;
} */

/* #root {
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
} */

.container-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* width: 100vw; */
  /* height: 3000px; */
}


.container.home {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* height: 100vh; */
  align-items: center;
}

.container.home .content {
  width: 100%;
}

.flex-row {
  display: flex;
  flex-wrap: wrap;
}

.space-between {
  justify-content: space-between;
}

.flex-end {
  justify-content: flex-end;
}

.mx-1 {
  margin-right: var(--spacing-one);
  margin-left: var(--spacing-one);
}

.mx-2 {
  margin-right: var(--spacing-two);
  margin-left: var(--spacing-two);
}

.my-1 {
  margin-top: var(--spacing-one);
  margin-bottom: var(--spacing-one);
}

.my-2 {
  margin-top: var(--spacing-two);
  margin-bottom: var(--spacing-two);
}

.px-1 {
  padding-right: var(--spacing-one);
  padding-left: var(--spacing-one);
}

.px-2 {
  padding-right: var(--spacing-two);
  padding-left: var(--spacing-two);
}

.py-1 {
  padding-top: var(--spacing-one);
  padding-bottom: var(--spacing-one);
}

.py-2 {
  padding-top: var(--spacing-two);
  padding-bottom: var(--spacing-two);
}

.card {
  width: 25%;
  text-align: center;
}

.error-text {
  color: var(--error);
}

@media screen and (max-width: 768px) {
  .card {
    width: 100%;
  }
}





header.header-nav {
  /* background-color: #ffffff; */
  background-color: #E6DABF;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 90px;
  width: 100%;
  /* margin-top: 10px; */
  padding-left: 30px;
  padding-right: 30px;
}


header nav {
  font-size: 26px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-height: 100%;

}

.logo-container {
  max-width: fit-content;
  max-height: fit-content;
}


header.header-nav nav .logo-container a {
  padding: 0px;
}

header.header-nav nav .logo-container a img {
  width: 116px;
  height: 70px;
  display: block;
  margin: auto; /* Centers the image horizontally */
}

header nav a {
  color: black;
  font-size: 18px;
  font-weight: 500;
  padding-left: 20px;
}

header nav input {
  width: 50vw;
}

.dropbtn {
  color: #000;
  background-color: transparent;
  border: none;
  color: black;
  font-size: 18px;
  font-weight: 500;
  padding: 0px;
  padding-left: 20px;
  font-family: "Aclonica", sans-serif;
}

.dropdown {
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {background-color: #ddd;}

.dropdown:hover .dropdown-content {display: block;}


.navbar {
  z-index: 100;
}

.list {
  list-style: none;
  display: flex;
  gap: 25px;
}

#toggler,
.navbar label {
    display: none;
}

.link {
    position: relative;
    display: inline-block;
}

.link::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: #fff;
    border-radius: 4px;
    scale: 0 1;
    transform-origin: left;
    transition: scale 0.25s;
}

.link:hover::before {
    scale: 1;
}

.menu {
  display: flex;
  flex-direction: row;
}



@media screen and (max-width: 600px) {
  .menu {
      width: 100vw;
      max-height: 0px;
      overflow: hidden;
      margin-left: -30px;
      background-color: white;
  }

  .list {
      flex-direction: column;
      align-items: center;
      padding: 20px;
      width: 100vw;
  }

  .navbar label {
      display: inline-flex;
      align-items: center;
      cursor: pointer;
  }
  
  #toggler:checked ~ .menu {
      max-height: 100%;
      overflow:visible;
  }
}





.eventListContainer {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 10px;
  /* background-color: #E0C697; */
}




@media screen and (max-width: 1095px) {
  .eventListContainer {
    justify-content: space-evenly;
    gap: 50px;
  }

  .eventListContainer:has(div a .artistCard) {
    justify-content: space-around;
    gap: 10px;
  }
}


.adminEvent {
 width: 300px;
 height: 350px;
 margin: 10px;
 border-radius: 10px;
 background-color: rgb(239, 239, 239);
 display: flex;
 flex-direction: column;
 justify-content: space-around;
 align-items: center;
}

.adminEvent h3 {
  padding: 15px;
  text-align: center;
}

.adminEvent img {
  width: 270px;
  height: 147px;
  border-radius: 10px;
  background-color: rgb(201, 197, 197);
}

/* Modal styles */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  border-radius: 5px;
  padding: 0px;
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 900px;
  width: 90%;
  height: 80%;
}

.modal .modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 10px 20px;
  box-shadow: 0px 0px 8px 1px rgba(42, 42, 42, 0.313);
}

.modal .modal-header h1 {
  font-size: 1.6rem;
}

.modal .modal-header button.cancel {
  width: 100%;
  max-width: 80px;
  height: 40px;
}

.modal .modal-header > * {
  margin: 0px;
  padding: 0px;
}

.modal .modal-body {
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  padding: 20px;
}

.modal .modal-body .event-id {
  background-color: #2972FF;
  color: #fff;
  border-radius: 4px;
  margin: 10px;
  text-wrap: wrap;
  word-wrap: break-word;
  width: 100%;
  text-align: center;
  padding: 4px 0px;
}

.modal .modal-body .ticket-section {
  overflow-x: scroll;
}

.ticket-section td button.add {
  width: 100%;
}

.ticket-section td button.cancel {
  width: 100%;
}


.modal-content .close {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 0;
  right: 0;
  font-size: 6vh;
  width: 60px;
  height: 60px;
  font-weight: bold;
  color: black;
  cursor: pointer;
}

/* .modal-content .modal-body {
  width: 70vw;
  display: flex;
  flex-direction: column;
  align-items: center;
} */

.modal-body .modal-container {
  padding-inline: 40px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-container > * {
  margin-bottom: 20px;
}

.modal-container input, textarea {
  width: 100%;
  padding: 5px;
  background-color: rgb(221, 221, 221);
  border: 1px solid rgb(137, 137, 137);
  border-radius: 5px;
}

/* .modal-content .modal-body > * {
  text-align: center;
} */


.modal-content table tbody tr td input {
  width: 100%;
  max-width: 180px;
  min-width: 70px;
}


.modal-form {
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
  justify-content: center;
}

.modal-form.bulk {
  display: flex;
  flex-wrap: wrap;
  overflow-y:hidden;
  justify-content: center;
}

.update-event {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin: 10px;
  width: calc(50% - 40px);
}

.form-group input {
  display: flex;
  border: none;
  background: hsl(0 0% 93%);
  border-radius: .25rem;
  padding: .75rem 1rem;
  flex: 0 1 1ch;
  margin: .5rem;
  /* width: 255px; */
}

.form-group select {
  display: flex;
  border: none;
  background: hsl(0 0% 93%);
  border-radius: .25rem;
  padding: .75rem 1rem;
  flex: 0 1 1ch;
  margin: .5rem;
  /* width: 255px; */
}

.form-group select option {
  display: flex;
  border: none;
  background: hsl(0 0% 93%);
  border-radius: .25rem;
  padding: .75rem 1rem;
  flex: 0 1 1ch;
  margin: .5rem;
  font-size: 16px;
}

.form-group label span {
  color: #E40B0B;
}

.form-group.image {
  width: 100%;
  background: hsl(0 0% 93%);
}

.form-group.image label {
  text-align: center;
}

.form-group.image .container {
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
}


button.add {
  background-color: #2972FF;
  color: #fff;
  padding: 5px 12px;
  border-radius: 8px;
  max-width: 200px;
  width: 100%;
}


button.cancel {
  background-color: #585151;
  color: #fff;
  padding: 5px 12px;
  border-radius: 8px;
  width: 200px;
}

button.back {
  background-color: #ffffff;
  font-size: 20px;
  padding: 5px 12px;
  border-radius: 8px;
  width: max-content;
  border: 1px solid rgba(55, 65, 81, 0.472);
  display: flex;
  flex-direction: row;
}

button.back a {
  color: var(--dark);
}


.update-event button.add {
  padding: 12px 12px;
  border-radius: 8px;
  width: 200px;
  margin-bottom: 20px;
}

.modal-form.create-event .form-group:has(.add) {
  width: 100%;
}

.modal-content .modal-form.create-event .form-group button.add {
  padding: 5px 12px;
  width: 100%;
  height: 40px;
}


button.add:disabled {
  background-color: #afafaf;
}

.delete-event {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  margin-top: 40px;
}

.delete-event p {
  color: #000000;
  font-weight: 500;
  font-size: 12px;
}

.delete-event button.delete {
  margin-top: 10px;
}

button.delete {
  background-color: #E40B0B;
  color: #fff;
  padding: 5px 12px;
  width: 200px;
}

@media screen and (max-width: 970px) {
  .form-group {
    display: flex;
    flex-direction: column;
    margin: 10px 20px;
    width: 100%;
  }

  .form-group input {
    margin: 0px;
  }

  button.add, button.delete {
    width: 80%;
  }

  .delete-event {
    align-items: center;
  }

  .delete-event p {
    width: 80%;
    text-align: center;
  }
}








@import url('https://fonts.googleapis.com/css2?family=Aclonica&family=Inter:wght@100..900&display=swap');


.eventCard {
  /* border: 2px solid black; */
  border-radius: 10px;
  width: 345px;
  /* height: 400px; */

  display: flex;
  flex-direction: column;
  align-items: center;

  color: #232F3E;
  box-shadow: 0px 0px 4px 1px rgba(42, 42, 42, 0.313);
  transition: 0.3s;
  padding: 5px;
  background-color: #E0C697;
  font-family: "Aclonica", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.eventCard:hover {
  cursor: pointer;
  box-shadow: 0px 0px 15px rgba(42, 42, 42, 0.512);
  /* transform: scale(1.01); */

}

.eventCard .card-header {
  width: 100%;
  height: 55%;
  /* height: 100%; */
}
.eventCard .card-header img {
  width: 100%;
  height: 100%;
  object-fit:cover;
  border-radius: 5px 5px 0px 0px;
}

.eventCard .card-body {
  width: 100%;
  height: 33%;
  padding: 20px;
  color: #fff;
}

.eventCard .card-body h3 {
  font-size: 18px;
  font-weight: 800;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; 
}


.eventCard .card-body p {
  font-size: 14px;
  font-weight: 500;
  /* color: #262626; */
}

.eventCard .card-footer {
  /* background-color: #000000cd; */
  width: 100%;
  height: 12%;
  border-radius: 0px 0px 5px 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  box-shadow: 0 0 2.6px 0.1px #888888;
  color: #000;
  background-color: #E40E0E;
}



.eventCard .card-footer p {
  font-weight: 600;
  color: #fff;
  margin: 0px;
}

.eventCard .card-footer i {
  font-weight: 600;
  color: #fff;
  margin: 0px;
}





@media screen and (max-width: 510px) {
  .eventListContainer > * {
    width: 100%;
  }
  .eventListContainer div:has(a .artistCard) {
    display: flex;
    justify-content: center;
  }

  .eventCard{
    width: 100%;
  }
}
.artistCard {
    border-radius: 10px;
    width: 250px;
    /* height: 400px; */
  
    display: flex;
    flex-direction: column;
    align-items: center;
  
    /* color: #232F3E; */
    color: #fff;
  /* background-color: #272f32; */
    transition: 0.3s;
    padding: 5px;
}

.artistCard .card-header{
  width: 150px;
  height: 150px;
  background-color: #888888;
  border-radius: 100px;
  overflow: hidden;
}

.artistCard .card-header img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.artistCard .card-body {
  width: 100%;
}

.artistCard .card-body h3{
  text-align: center;
  white-space: nowrap; /* Prevent the title from wrapping */
  overflow: hidden; /* Hide any overflow */
  text-overflow: ellipsis; /* Show ellipsis for overflow */
}






.details {
  display: flex;
  justify-content: center;
  width: 100%;
  /* background-image: url('./assets/326.jpg'); */
}

.eventDetails {
  display: flex;
  flex-direction: column;
  width: 100vw;
  /* padding: 20px; */
  /* display: flex;
  flex-direction: column; */
}

.eventDetails .event-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.eventDetails .event-header .event-image {
  background-image: url('./assets/326.jpg');
  width: 100%;
  height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;

}

.eventDetails .event-header .event-image img {
  /* width: 500px; */
  max-width: 100%;
  max-height: 100%;
  /* Ensure the image doesn't exceed its natural size */
  width: auto;
  height: auto;
  padding: 20px;
}

.eventDetails .event-body {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center items horizontally */
  align-items: flex-start; /* Adjust vertical alignment based on your design */
  /* width: 100%; */
  max-width: 1100px; 
  width: calc(100% - 40px);
  margin: 0 auto;
  box-sizing: border-box;
  margin-top: 40px;
  margin-bottom: 40px;
}

.eventDetails .event-body .event-content {
  width: 100%;
  max-width: 635px;
  padding: 10px;
  box-sizing: border-box;
}

.eventDetails .event-body h2 {
  /* text-align: center; */
}

.eventDetails .event-body .ticketContainer {
  width: 100%;
  max-width: 425px;
  /* min-width: 290px; */
  padding: 10px;
  box-sizing: border-box;
}

.eventDetails .event-body .ticketContainer p {
  width: 100%;
  margin-bottom: 5px;
}

.eventDetails .event-body .ticketContainer p img {
  width: 20px;
}

@media screen and (max-width: 1099px) {
  .eventDetails .event-body .ticketContainer {
    width: 100%;
    max-width: 635px;
  }

  .eventDetails .event-body .event-content {
    width: 100%;
  }
}

.eventDetails .event-body .ticketContainer .ticketList {
  height: 300px;
  width: 100%;
  overflow-y: scroll;
  -webkit-box-shadow: inset 0px -30px 20px -28px rgba(0,0,0,0.75);
  -moz-box-shadow: inset 0px -30px 20px -28px rgba(0,0,0,0.75);
  box-shadow: inset 0px -30px 20px -28px rgba(0,0,0,0.75);
}

.ticketContainer .ticketList .ticket-card {
  background-color: #ffffff64;
  box-shadow: 0px 0px 2px 0.5px var(--m-primary);
  margin-bottom: 5px;
  height: 80px;
  width: calc(100% - 12px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.ticketContainer .ticketList .ticket-card .ticket-details {
  display: flex;
  align-items: center;
  height: 100%;
  width: 70%;
  justify-content: flex-start;
}

.ticket-details .ticket-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ticket-details .ticket-section.ticket-price {
  background-color: var(--m-primary);
  height: 100%;
  min-width: 60px;
  border-radius: 10px 0px 0px 10px;
  box-shadow: 6px 0px 7px -5px rgba(0, 0, 0, 0.204);
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  padding: 5px;
}

.ticket-details .ticket-section.ticket-main {
  width: fit-content;
  height: 100%;
  padding-left: 10px;
  align-items: start;
  font-size: 18px;
  /* max-width: 100px; */
}


.ticket-details .ticket-section.ticket-main p {
  font-size: 12px;
  display: flex;
  align-items: flex-end;
  gap: 5px;
}

.ticket-card .ticket-counter {
  height: 100%;
  width: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding-right: 10px;
  padding-left: 10px;
}

.ticket-card .ticket-counter button {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 26px;
  height: 26px;
  padding: 0px;
  border-radius: 20px;
  font-size: 18px;
}

.ticket-card .ticket-counter button {
  background-color: #fff;
  color: var(--m-primary);
  border: 1px solid var(--m-primary);
}

.ticket-card .ticket-counter span {
  font-size: 20px;
  padding: 5px;
}

.ticket-confirm {
  background-color: var(--m-primary);
  color: #fff;
  font-size: 20px;
  padding: 10px;
  border-radius: 0.5rem;
}

.ticket-confirm:hover {
  background-color: #fff;
  color: var(--m-primary);
  border: 1px solid var(--m-primary);
}

.order-details {
  font-family: "Inter", sans-serif;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  gap: 30px;
}

.order-summary {
  min-width: 420px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 9.1px 0px rgba(0,0,0,0.25);
  margin-top: 30px;
  padding: 20px;
  border-radius: 8px;
  border-top-style: solid;
  border-color: var(--m-primary);
  border-width: 10px;
}

.order-summary .event-details {
  margin-bottom: 20px;
}

.order-summary .event-details h2 {
  margin: 0px;
  font-size: 25px;
  font-weight: bold;
  font-family: "Inter", sans-serif;
}

.order-summary .event-details .event-title {
  font-size: 20px;
  word-wrap: break-word;
  white-space: normal;
  padding: 10px;
  text-align: center;
}

.order-summary .event-details .image-container {
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
}

.order-summary .event-details .image-container img {
  max-width: 250px;
  width: 80%;
  box-shadow: 0px 0px 9.1px 0px rgba(0, 0, 0, 0.609);
  border-radius: 5px;
}

.order-summary .event-details p i {
  text-align: start;
}

.order-summary ul {
  margin: 0px;
  padding: 0px;
}

.order-summary ul li {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.order-summary ul li .ticket-info {
  display: flex;
  flex-direction: row;
  justify-content: start;
  gap: 8px;
}

.order-summary ul li.subtotal-price {
  margin-top: 15px;
}

.order-summary ul li.total-price {
  font-weight: bold;
  font-size: 18px;
}


form.confirm-form {
  min-width: 420px;
  margin-top: 30px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  font-family: "Inter", sans-serif;
}

.section-title {
  /* margin-top: 20px; */
  margin-bottom: 15px;
}

.section-title h2 {
  margin: 0px;
  font-size: 25px;
  font-weight: bold;
  font-family: "Inter", sans-serif;
}

.section-title hr {
  margin: 0px;
  border: 0px;
  width: 118px;
  height: 5px;
  background-color: var(--m-primary);
}

form.confirm-form label {
  margin-bottom: 5px;
}

form.confirm-form label span {
  color: #E40B0B;
}

form.confirm-form input {
  border-radius: 4px;
  border: 0px;
  box-shadow: inset 0px 0px 3px 0px rgba(0, 0, 0, 0.554);
  height: 35px;
  margin-bottom: 10px;
  padding: 8px;
}

form.confirm-form label.terms-check {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  margin-bottom: 15px;
  margin-top: 5px;
}

form.confirm-form label.terms-check input {
  border-radius: 4px;
  border: 0px;
  box-shadow: none;
  height: 15px;
  margin: 0px;
}

form.confirm-form label.terms-check a {
  color: #2972FF;
  text-decoration: underline;
}

form.confirm-form button {
  background-color: var(--m-primary);
  color: #FFF;
  padding: 10px;
  border-radius: 6px;
  font-weight: 600;
  font-family: "Inter", sans-serif;
  transition: 0.3s;
}

form.confirm-form button[disabled] {
  cursor:not-allowed;
  background-color: #474747e9;
  border: 2px solid rgba(111, 111, 111, 0.153);
}






@media screen and (max-width: 940px) {
  .order-details {
    width: 90%;
    flex-direction: column;
    gap: 0px;
  }

  .order-summary {
    min-width: 100%;
    margin-bottom: 20px;
  }

  form.confirm-form {
    min-width: 100%;
    margin-top: 0px;
  }
}

.container-admin {
  display: flex;
  flex-direction: column;
  width: 90%;
  justify-content: center;
  margin-top: 30px;
  max-width: 1700px;
  font-family: Arial, Helvetica, sans-serif;
}

.container.test .event {
  padding: 20px;
  border: 2px solid green;
  margin-bottom: 20px;
}

.container.test .event .product {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  border-bottom: 2px solid red;
}

.container.test .event .product > * {
  margin-left: 20px;
}


.purchased {
  color: #F3A847;
}

.attended {
  color: chartreuse;
}


.admin .create-event {
  display: flex;
  justify-content: center
}

.admin .create-event button.add {
  width: 130px;
  padding: 8px 12px;
  border-radius: 8px;
  font-size: 14px;
}


@media screen and (max-width: 970px) {

  .admin .create-event button.add {
    width: 100%
  }
}






@import url('https://fonts.googleapis.com/css2?family=KoHo:wght@700&family=Palanquin+Dark:wght@700&display=swap');



.home .header {
  background-image: url(./assets/concert-bg-mini-dark.webp);
  background-position: center center;
  background-repeat: no-repeat;
  background-size:cover;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}

@media screen and (max-width: 1095px) {
  .home .header {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  .home .header .title {
    padding-right: 20px;
    padding-left: 20px;
  }
}

.home .content .header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home .header .title {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
  padding-bottom: 25px;
}

.home .header .title svg {
  width: 80%;
  height: 80%;
  max-width: 851px;
  max-height: 238px;
}

.home .header .title h1 {
  font-size: 60px;
  font-family: 'KoHo', sans-serif;
  font-family: 'Palanquin Dark', sans-serif;
  color: #000000;
}

.home .header .sub-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 55px;
  color: rgb(255, 255, 255);
}

.home .header .sub-title p {
  text-align: center;
  font-size: 26px;
  text-shadow: 2px 2px black;
}





@media screen and (max-width: 1064px) {
  .home .header .sub-title p {
    text-align: center;
    font-size: 2.9vw;
  }

  .home .header .title {
    padding-bottom: 2vw;
  }
}
.searchbar {
  background: #ffffff;
  height: 40px;
  width: 640px;
  max-width: 80vw;
  border-radius: 10px;
  box-shadow: 0 0 2.6px 1px #888888;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.searchbar i {
  padding-left: 10px;
  padding-right: 10px;
  color: #000000;
}

.searchbar form {
  width: 100%;
  display: flex;
  align-items: center;
}

.searchbar input.search-input {
  border: none;
  background: none;
  outline: none;
  float: left;
  padding: 0;
  padding-left: 10px;
  font-size: 16px;
  transition: 0.4s;
  height: 100%;
  width: 100%;
  color: #000000;
}

.searchbar input.search-input::placeholder {
  color: #000000;
}

.searchbar input.search-input:focus {
  box-shadow: none;
}


.home .content .divider {
  margin-top: 80px;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.42);
}

.events-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-right: 30px;
  padding-left: 30px;
  margin-bottom: 20px;
}

.events-section h1 {
  font-weight: bold;
  margin-top: 40px;
  margin-bottom: 40px;
  color: #E40E0E;
}



.results {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Adjust the gap between events as needed */
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

.event {
  display: flex;
  align-items: center; /* Center items vertically */
  height: 100px;
  width: 100%;
  max-width: 800px;
  border-radius: 7px;
  box-shadow: 0px 0px 4px 1px rgba(42, 42, 42, 0.313);
}

.preview {
  flex: 0 0 auto; /* Prevent the image from growing or shrinking */
  width: 100px; /* Adjust the width of the image as needed */
  height: 100px; /* Set the height of the image */
  overflow: hidden; /* Hide any overflow */
  margin-right: 20px; /* Adjust spacing between image and content */
  border-radius: 7px 0px 0px 7px;
}

.preview img {
  width: 100%; /* Make the image fill the container */
  height: 100%; /* Make the image fill the container */
  object-fit: cover; /* Preserve aspect ratio and cover the container */
}

.results .content {
  flex: 1; /* Make the content flex to fill the remaining space */
  overflow: hidden; /* Hide any overflow */
}

.results .content h3 {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 28px;
  width: 100%;
}

.results p {
  margin: 5px 0; /* Adjust paragraph margins as needed */
  white-space: nowrap; /* Prevent the title from wrapping */
  overflow: hidden; /* Hide any overflow */
  text-overflow: ellipsis; /* Show ellipsis for overflow */
}

.results .summary {
  width: 100%;
  max-width: 800px;
  margin-top: 20px;
}



@media screen and (min-width: 840px) {
  .results {
    align-items: center;
  }

  .content h1 {
    white-space: wrap; /* Prevent the title from wrapping */
    overflow: hidden; /* Hide any overflow */
    text-overflow:clip; /* Show ellipsis for overflow */
    font-size: 28px;
  }

  .events-section h1 {
    font-size: 32px;
  }
}












#payment-form {
  max-width: 600px;
  width: 90%;
  margin-top: 20px;
  margin-bottom: 20px;
}

#payment-form .order-summary {
  margin-bottom: 20px;
}

#payment-form .order-summary .event-details .title {
  text-align: center;
}

#payment-form #payment-element {
  margin-bottom: 20px;
  border-radius: 8px;
}

#payment-form button {
  background-color: var(--m-primary);
  color: #FFF;
  padding: 10px;
  border-radius: 6px;
  font-weight: 600;
  /* letter-spacing: 0.7px; */
  font-family: "Inter", sans-serif;
  transition: 0.3s;
  width: 100%;
}

#payment-form button[disabled] {
  cursor:not-allowed;
  background-color: #474747e9;
  border: 2px solid rgba(111, 111, 111, 0.153);
}


ul {
  list-style-type: none;
  padding: 0px;
  margin: 0px;
}

#payment-message {
  text-align: center;
  background-color: white;
  padding: 10px;
}




footer {
  /* margin-top: 40px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

footer > * {
  width: 100%;
}


footer .nav-section {
  padding: 20px;
  /* background-color: #000000cd; */
  background-color: #000;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

footer .nav-section > * {
  width: 33.3333%;
  display: flex;
  justify-content: center;
  align-items: center;
}

footer .nav-section .socials-container ul {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  font-size: 28px;
}

footer .nav-section .socials-container ul li a {
  text-decoration: none;
  color: #fff;
}


footer .nav-section .links-container ul {
  display: flex;
  flex-direction: row;
}

footer .nav-section .links-container ul li {
  padding: 10px;
  text-align: center;
}

footer .nav-section .links-container ul li a {
  color: #fff;
}


footer .copy-section {
  /* margin-top: 10px; */
  text-align: center;
}

footer .copy-section p {
  margin-top: 10px;
  font-size: 12px;
}


@media screen and (max-width: 768px) {
  footer .nav-section {
   flex-direction: column;
   align-items: center;
  }
 
  footer .nav-section .contact-container {
   width: 100%;
 }
 
 footer .nav-section > * {
   width: 100%;
   padding-bottom: 10px;
 }
 }


.admin.container {
  border: 1px solid rgba(55, 65, 81, 0.472);
  border-radius: 14px;
  margin-top: 30px;
  margin-bottom: 15px;
  overflow-x:hidden;
}

.admin.container .header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  padding: 10px 12px;
  background-color: #dedddd79;
  border-bottom: 1px solid rgba(55, 65, 81, 0.472);
}

.admin.container .header .title h2 {
  font-size: 20px;
  line-height: normal;
  margin: 0px;
}

.admin.container .header .title p {
  font-size: 14px;
  line-height: normal;
}


.admin.container .table-container {
  width: 100%;
  border-collapse: collapse;
  overflow: auto;
}


.container table tbody tr {
  cursor:pointer;
}

.admin.container table th, td {
  padding: 10px;
  border-bottom: 1px solid #ddd;
  text-align: left;
}

.admin.container table td button.delete {
  width: 100%;
}

.admin.container table th {
  background-color: #f2f2f2;
}

.admin.container ul {
  padding: 0;
}

.admin.container .ticket {
  border: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 5px;
  list-style-type: none; /* Remove bullet points */
}

.ticket p {
  margin: 5px 0;
}



.expanded-row {
  background-color: #f9f9f9; /* Background color when expanded */
}

/* Arrow icon styles */
.arrow-down {
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid black;
  margin-left: 5px;
}

.arrow-right {
  display: inline-block;
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid black;
  margin-left: 5px;
}




.admin.container .footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.admin.container .footer > * {
  width: fit-content;
}

.admin.container .footer .pagination {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.tickets-table {
  width: 100%;
}

.admin.container table.tickets-table thead tr th {
  padding: 0px;
}

.tickets-table td {
  padding: 0px;
}

.tickets-table th {
  background-color: #f2f2f2;
}

.tickets-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}







.container-admin .container {
  max-width: 100%;
}



table {
  width: max-content;
  min-width: 100%; /* Ensure the table takes full width initially */
  border-collapse: collapse;
}

table tbody tr td {
  width: fit-content;
}

table th, td {
  padding: 10px;
  border-bottom: 1px solid #ddd;
  text-align: left;
}

table td img {
  width: 50px;
}

table th {
  background-color: #f2f2f2;
}

table tr:nth-child(even) {
  background-color: #f9f9f9;
}

table .status {
  font-weight: bold;
}

table ul {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
}

table ul li {
  list-style-type: none;
  margin-bottom: 5px;
  width: 100%;
}





.login {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(./assets/concert-bg-mini-dark.webp);
  background-size: cover;
  background-position: center;
}

.login .login-card {
  background-color: #FFF;
  width: 100%;
  max-width: 500px;
  height: 400px;
  padding: 20px;
  margin: 40px;
  border-radius: 10px;
  box-shadow: 0px 0px 4px 1px rgba(42, 42, 42, 0.313);
  display: flex;
  flex-direction: column;
}

.login .login-card .login-title {
  font-size: 32px;
  margin-bottom: 30px;
}

.login .login-card .card-body {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}


.login-card .card-body form input {
  width: 100%;
  font-size: 24px;
  padding: 10px;
  border-radius: 7px;
  margin-bottom: 30px;
}

.login .login-card .card-body form button {
  background-color: var(--m-primary);
  color: #FFF;
  width: 100%;
  font-size: 24px;
  padding: 10px;
  margin-top: 10px;
}

@media screen and (max-height: 400px) {
  .login {
    align-items: flex-start;
    overflow-y: scroll;
  }
}










.artist {
  width: 100%;
}

.artistDetails {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 40px;
}

.artistDetails .artist-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.artistDetails .artist-header .artist-image {
  width: 150px;
  height: 150px;
  background-color: #888888;
  border-radius: 100px;
  overflow: hidden;
}

.artistDetails .artist-header .artist-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.artistDetails .artist-header .artist-title {
  text-align: center;
}


.artistDetails .artist-body {
  max-width: 600px;
  width: 90%;
}

.artistDetails .artist-body .artist-socials {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 10px;
  margin-bottom: 20px;
}

.artistDetails .artist-body .artist-socials img {
  width: 37px;
  height: 37px;
  object-fit: cover;
  border-radius: 9px;
  box-shadow: 0px 0px 11px 3px rgba(42, 42, 42, 0.313);
}

.artistDetails .artist-body .artist-summary {
  text-align: center;
  margin-top: 20px;
}

.artistDetails .artist-body .ticketContainer {
  margin-top: 20px;
}

.artistDetails .artist-body .ticketContainer .section-title {
  font-size: 24px;
}

.artistDetails .artist-body .ticketContainer .ticketList {
  display: flex;
  flex-direction: column;
  gap: 20px;
}





.container.success {
  width: 100%;
}

.container.success .content {
  background-image: url(./assets/concert-bg-mini-dark.webp);
  background-position: center center;
  background-repeat: no-repeat;
  background-size:cover;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}

.container.success .content .confirmation-card {
  width: 90%;
  max-width: 350px;
  /* height: 600px; */
  margin-top: 40px;
  margin-bottom: 40px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 9.1px 0px rgba(0,0,0,0.25);
  margin-top: 30px;
  padding-top: 20px;
  border-radius: 8px;
  border-top-style: solid;
  border-color: var(--m-primary);
  border-width: 10px;
}

.success .content .confirmation-card .header {
  width: 100%;
}

.success .content .confirmation-card .header .title {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  margin: 0px;
}

.confirmation-card .header .image-container {
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
}

.success .confirmation-card .header .image-container img {
  /* max-width: 250px; */
  width: 155px;
  box-shadow: 0px 0px 9.1px 0px rgba(0, 0, 0, 0.609);
  border-radius: 5px;
}



.success .content .header .title {
  background-color: #fff;
  margin: 20px;
}

.success .content .confirmation-card .divider {
  width: 100%;
  height: 1px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #5851514b;
}


.success .content .confirmation-card .body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.success .content .confirmation-card .body .orderNumber h2 {
  font-size: 16px;
  margin: 0px;
}

.success .content .confirmation-card .body .orderNumber p {
  letter-spacing: 4px;
  font-weight: 800;
  font-size: 20px;
}

.success .content .confirmation-card .body .info-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 15px;
}


.success .content .confirmation-card .body .info-section {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.success .content .confirmation-card .body .info-section .info {
  font-weight: 700;
}

.success .content .confirmation-card .footer {
  text-align: center;
  padding: 10px;
  padding-top: 0px;
}

.success .content .confirmation-card .footer span {
  color: #08080893;
}





.terms-page .header h1 {
  color: #fff;
  font-size: 48px;
  font-weight: 600;
  text-decoration: underline;
}


.terms-container {
  margin: 30px;
}

.terms-container a {
  text-decoration: underline;
  color: #E40B0B;
}






.scanner {
  margin-top: 20px;
  width: 80%;
  margin-bottom: 20px;
}


.scanner .result {
  border-radius: 20px 20px 0px 0px;
  box-shadow: 0px 0px 10px 1px rgba(76, 76, 76, 0.313);
  margin-top: 10px;
}

.scanner .result .orderNumber {
  width: 100%;
  border-radius: 20px 20px 0px 0px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0px;
}

.scanner .result .detail h3 {
  font-size: 20px;
  margin-top: 5px;
}

.scanner .result .detail p {
  margin-bottom: 0px;
}

.scanner .result .orderNumber span {
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 4px;
}

.scanner .result .divider {
  margin-top: 0px;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.23);
}

.scanner .result .detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5px;
  padding: 10px;
}

.scanner .info-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 15px;
  width: 100%;

}

.scanner .info-container > * {
  width: 100%;
}

.scanner .info-container .info-section {
  text-align: center;
}

#reader__dashboard_section div:has(#html5-qrcode-anchor-scan-type-change) {
  display: none;
}





.scanner form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.scanner form input {
  width: 100%;
  padding: 5px;
  background-color: rgba(242, 242, 242, 0.295);
  border: 1px solid rgb(137, 137, 137);
  border-radius: 5px;
  font-size: 24px;
}

.scanner form button {
  margin-top: 10px;
  width: 100%;
  padding: 5px;
  color: #fff;
  background-color: #2972FF;
  border: 1px solid rgb(137, 137, 137);
  border-radius: 5px;
  font-size: 24px;
}




#reader {
  margin-top: 10px;
  border-radius: 0px 0px 20px 20px;
  padding: 10px;
  box-shadow: 0px 0px 10px 1px rgba(76, 76, 76, 0.313);
}

#reader__dashboard_section {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

#reader__dashboard_section_csr span, #reader__dashboard_section_csr select {
  font-size: 18px;
}

#html5-qrcode-button-camera-start, #html5-qrcode-button-camera-stop {
  font-size: 32px;
  margin: 10px;
}





@media screen and (min-width: 840px) {
  #reader {
    display: none;
  }
  
}









.stats {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 30px;
  gap: 20px;
}

.stats .stat {
  background-color: #888888;
  border: 1px solid rgba(55, 65, 81, 0.472);
  border-radius: 14px;
  margin-bottom: 15px;
  overflow-x: hidden;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
  padding: 10px 12px;
  background-color: #dedddd79;
  border-bottom: 1px solid rgba(55, 65, 81, 0.472);
  text-align: center;
  width: 400px;
}

.stats .stat h2 {
  font-size: 30px;
}

.stats .stat p {
  font-size: 24px;
}






.event-summary {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #dedddd79;
  padding: 20px;
  margin-top: 10px;
  border-radius: 14px;
  border: 1px solid rgba(55, 65, 81, 0.472);
}

.event-summary .details {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-top: 10px;
}

.event-summary .details h1 {
  word-break:break-all
}

.event-summary .details p {
  text-align: center;
  width: 33.333%;
  min-width: fit-content;
  margin-bottom: 10px;
}

.contact {
  min-height: calc(100vh - 200px);
  max-width: 600px;
  width: 90%;
  /* background-color: #afafaf; */
  text-align: center;
}

.contact form {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #000;
  color: #fff;
  border-radius: 17px;
  font-size: 24px;
  margin-top: 20px;
}

.contact form div {
  display: flex;
  justify-content: space-between;
}

.contact form div label {
  width: 120px;
  margin-right: 10px;
}

.contact form div input, .contact form div textarea {
  background-color: #fff;
  width: calc(100% - 150px);
}




div#voting-page {
  display: flex;
  flex-direction: column;
  align-items: center;
} 

div#voting-page button.return {
  margin-top: 20px;
}

div#voting-page button.return a {
  background-color: #fff;
  color: #000;
  font-size: 24px;
}


.nominee.selected {
  border: 4px solid rgb(214, 6, 6);
  background-color: #dddddd76;
}

body:has(#voting-page) {
  background-color: #000;
  color: #fff;
  /* height: max-content; */
}
div#voting-page h1 {
  margin-top: 20px;
  text-align: center;
}

div#voting-page p {
  text-align: center;
}

.voting-container {
  display: flex;
  flex-direction: column;
}

.voting-container #captcha {
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.voting-container button {
  font-size: 30px;
  margin: 20px;
}

.voting-container .nominee-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.voting-container .nominee-container .nominee {
  width: 300px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 20px;
  margin: 10px;
}

.nominee-container .nominee img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

.voting-container .nominee-container .nominee h3 {
  font-size: 25px;
}

#voting-page .tutorial-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#voting-page footer {
  margin-top: 80px;
}

#voting-page footer .contact-container {
  width: 100%;
}

#voting-page footer .contact-container ul {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#voting-page footer .contact-container ul li a button.delete {
  font-size: 24px;
}

#voting-page footer .contact-container ul li.mobile a button.delete {
  display: none;
}

#voting-page footer .contact-container ul li.desktop a button.delete {
  display:block;
}

#voting-page footer .nav-section {
  padding-bottom: 0px;
}


@media screen and (max-width: 740px) {
  #voting-page footer .contact-container ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  #voting-page footer .contact-container ul li.mobile a button.delete {
    display:block;
  }

  #voting-page footer .contact-container ul li.desktop a button.delete {
    display: none;
  }
  
}
/* =========================
Custom Input range 
=============================*/

/* Input range - chrome and safari */

body:has(.audio-player) {
  /* background-color: #000; */
  background-image: url('assets/pexels-wendy-wei-1306791dark.jpg');
  background-size: cover;
}

.audio-player {
  margin-top: 20px;
  padding: 20px;
  background-color: #000000c3;
  margin: 20px;
  border-radius: 20px;
  color: #fff;
}

.song-detail .audio-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  text-align: center;
}

.song-detail .audio-info .audio-image img {
  min-width: 120px;
  margin-right: 20px;
}

.song-detail .audio-info .text {
  color: #fff;
}

.song-detail .audio-info .text .title {
  font-size: 26px;
  font-weight: 600;
}

.song-detail .audio-info .text .by {
  /* font-size: 26px; */
  font-weight:100;
}

.controls button, .volume button {
  background-color: transparent;
  color: #fff;
  font-size: 32px;
}


.volume {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.volume input[type="range"] {
  width: 200px;
}





.progress {
  display: flex;
  flex-direction: row;
}


input[type="range"] {
  --range-progress: 0;
  
  position: relative;
  background:#000;
  width: 100%;
  height: 2px;
  border-radius: 2px;
  cursor: pointer;
}

/* Input range - firefox */
input[type="range"]::-moz-range-track {
  position: relative;
  background:#000;
  width: 100%;
  height: 2px;
  border-radius: 2px;
  cursor: pointer;
}

/* played progress length - Chrome & safari*/
input[type="range"]::before {
  content: '';
  height: 2px;
  background: rgb(236 0 0);
  width: var(--range-progress);
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
  position: absolute;
  top: 0;
  left: 0;
}

/* played progress length - firefox */
input[type="range"]::-moz-range-progress {
  background: rgb(236 0 0);
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
  height: 2px;
}

/* slider thumb - chrome and safari */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  border: none;
  background-color: rgb(236 0 0);
  cursor: pointer;
  position: relative;
}

/* dragging thumb - chrome and safari */
input[type="range"]:active::-webkit-slider-thumb {
  transform: scale(1.2);
}

/* slider thumb - firefox */
input[type="range"]::-moz-range-thumb {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background: rgb(236 0 0);
  cursor: pointer;
  border: transparent;
  position: relative;
}
/* dragging thumb - firefox */
input[type="range"]:active::-moz-range-thumb {
  transform: scale(1.2);
}




.audio-page {
  width: 100%;
  max-width: 1200px;
}
.audio-page .nav {
  width: calc(100% - 40px);
  height: 80px;
  background-color: #000000c3;
  border-radius: 50px;
  margin: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 40px;
  padding-right: 40px;
  justify-content: space-between;
  color: #e4b367
}

/* .audio-page .nav .title-container, .audio-page .nav .search-container  {
  height: 100%;
  width: 40px;
} */

.audio-page .nav img {
  width: 40px;
  height: 50px;
}

.audio-page .nav .title-container a{
  font-size: 24px;
  text-align: center;
  color: #e4b367
}

.audio-page .nav i {
  font-size: 24px;
}

.audio-player .inner .socials ul {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.audio-player .inner .socials ul li {
  color: #fff;
  font-size: 24px;
}

.audio-player .inner .socials ul li a {
  color: #fff;
}

@media screen and (max-width: 637px) {
  .controls-wrapper .controls {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .song-detail .audio-info {
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .song-detail .audio-info .audio-image img {
    margin: 0px;
  }
}


@media screen and (max-width: 425px) {
  .audio-page .nav i {
    display: none;
  }
}









.top-nominee {
  font-weight: bold;
  color: #4CAF50; /* A green color to highlight the top nominee */
  background-color: #f0f0f0; /* A light gray background for more emphasis */
}
